<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center d-theme-dark-bg">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img style="width: 100%;" src="@/assets/images/pages/login.png" alt="login"
                   class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="px-8 pt-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Inicia sesión</h4>
                  <p>Bienvenido de vuelta.</p>
                </div>

                <login-firebase></login-firebase>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>
import LoginFirebase from './Firebase'

export default {
  components: {
    LoginFirebase
  }
}
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 400px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
